import { Route, Routes } from "react-router-dom";
import HomePage from "../../pages/Home";
import RoomsPage from "../../pages/Rooms";
import ContactsPage from "../../pages/Contacts";
import PricesPage from "../../pages/Prices";
import Room from "../../pages/Room";
import Booking from "../../pages/Booking";
import ThankYou from "../../pages/ThankYouPage";

const Main = () => {
  return (
    <main>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/rooms" element={<RoomsPage />} />
        <Route path="/rooms/:id" element={<Room />} />
        <Route path="/prices" element={<PricesPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/success" element={<ThankYou />} />
      </Routes>
    </main>
  );
};

export default Main;
