import { useTranslation } from "react-i18next";
import "./index.css";
const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <p>{t("footer")}</p>
    </footer>
  );
};

export default Footer;
