import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { Button } from "@mui/material";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import { roomsData } from "../../data/data";
import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Booking from "../Booking";
import ThankYou from "../ThankYouPage";

const Room = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const [bookNow, setBookNow] = useState(false)
  const { t } = useTranslation();
  const [redirectToSuccess, setRedirectToSuccess] = useState(false)
  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  return (
    <div className="single-room-page-root">
      <div className="single-room-image-container"></div>
      <Button
        className="single-room-back-button"
        variant="contained"
        color="warning"
        onClick={() => {
          navigate("/rooms");
        }}
      >
        <ArrowBackOutlined></ArrowBackOutlined>&nbsp; {t("room-back-button")}
      </Button>
      <h1 className="room-name">
        {t(roomsData.filter((room) => room?.id == id)[0]?.type)}
      </h1>
      <div className="room-slider-and-info-container">
        <div className="room-slider-container">
          <div className="room-first-slider-container">
            <Slider
              nextArrow={
                <ArrowForwardOutlined
                  color="warning"
                  sx={{
                    ":hover": {
                      color: "black",
                      background: "#ed6c02",
                    },
                  }}
                />
              }
              prevArrow={
                <ArrowBackOutlined
                  color="warning"
                  sx={{
                    ":hover": {
                      color: "black",
                      background: "#ed6c02",
                    },
                  }}
                />
              }
              asNavFor={nav2}
              ref={(slider) => (sliderRef1 = slider)}
            >
              {roomsData
                .filter((room) => room?.id == id)[0]
                .images.map((image) => {
                  return <img alt="" src={image}></img>;
                })}
            </Slider>
          </div>
          <div className="room-second-slider-container">
            <Slider
              asNavFor={nav1}
              ref={(slider) => (sliderRef2 = slider)}
              slidesToShow={4}
              swipeToSlide={true}
              focusOnSelect={true}
              arrows={false}
            >
              {" "}
              {roomsData
                .filter((room) => room?.id == id)[0]
                .images.map((image) => {
                  return <img alt="" src={image}></img>;
                })}
            </Slider>
          </div>
        </div>
        {
          (bookNow && !redirectToSuccess) ? <Booking roomType={t(roomsData.filter((room) => room?.id == id)[0]?.type)} setRedirectToSuccess={setRedirectToSuccess} /> : bookNow && !!redirectToSuccess ?
            <ThankYou />
            :
            <div className="room-info-container">
              <span>{t(roomsData.filter((room) => room?.id == id)[0].info)}</span>
              <ul className="room-info-ul">
                {roomsData
                  .filter((room) => room?.id == id)[0]
                  .bullets.map((bullet) => {
                    return <li>{t(bullet)}</li>;
                  })}
              </ul>
              <Button
                className="room-info-button"
                variant="contained"
                color="warning"
                onClick={() => setBookNow(true)}
              >
                {t("room-book-now")}
              </Button>
              <p>
                {t("room-question")}{" "}
                <a className="room-info-a" href="/contacts">
                  {t("room-contact")}
                </a>
                &nbsp;!
              </p>
            </div>
        }

      </div>
    </div>
  );
};

export default Room;
