import React, { useRef, useState } from 'react';
import { Container, Typography, Button, Box, Paper, TextField} from '@mui/material';
import emailjs from '@emailjs/browser';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { t } from 'i18next';
import './index.scss'

const Booking = ({ setRedirectToSuccess, roomType }) => {
    const [firstName, setFirstName] = useState('');
    const [surname, setSurName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fromDate, setFromDate] = React.useState(dayjs(new Date()));
    const [toDate, setToDate] = React.useState(dayjs());

    const fromDateFormated = new Date(fromDate.toDate());
    const toDateFormated = new Date(toDate.toDate());

    const roomTypeTr = roomType.includes('Double') ? 'Двойна стая' : "Студио" 
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return`${day}.${month}.${year}`;
    };

    const formatCartItems = () => {
        return `Резервация на името на ${firstName} ${surname}. Телефонен номер - ${phoneNumber}. Запазена ${roomTypeTr} за дати - ${formatDate(fromDateFormated)}-${formatDate(toDateFormated)}`
    };

    const form = useRef(null);
    const template_id = 'template_815mpxo';
    const service_id = 'service_221pjsk';
    const public_key = 'uhrEUrVoYqiv4kN7G';
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.current) {
            emailjs
                .sendForm(service_id, template_id, form.current, {
                    publicKey: public_key,
                })
                .then(
                    () => {
                        setRedirectToSuccess(true)
                        console.log('SUCCESS!');
                    },
                    (error) => {
                        console.log('FAILED...', error);
                    },
                );
        }
    }
    
    return (
        <Container sx={{ paddingBottom: '35%' }}>
            <Typography variant="h4" component="h2" color="#ed6c02" textAlign={'center'} gutterBottom>
                {t('reserve-title')}
            </Typography>
            <Container component="main" maxWidth="sm">
                <Paper elevation={3} sx={{ p: 3, backgroundColor: 'transparent' }}>
                    <Box ref={form} component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                        <TextField
                            sx={{ display: 'none' }}
                            name='reservation'
                            value={formatCartItems()}
                        />
                        <Box component='div' display={'flex'}>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disablePast
                                    closeOnSelect
                                    autoFocus
                                    slotProps={{
                                        inputAdornment: {

                                        },
                                        layout: {
                                            sx: {
                                                color: '#ed6c02',
                                                border: '2px solid #ed6c02', backgroundColor: 'rgba(119, 119, 119, 0.19)',

                                            }
                                        },
                                        textField: {
                                            InputLabelProps: {
                                                sx: {
                                                    color: 'white',
                                                },
                                                color: 'warning',
                                            },
                                            inputProps: {
                                                style: {
                                                    color: 'white'
                                                }
                                            },
                                            focused: false

                                        },

                                        rightArrowIcon: {
                                            sx: {
                                                color: '#ed6c02'
                                            }
                                        },
                                        leftArrowIcon: {
                                            sx: {
                                                color: '#ed6c02'
                                            }
                                        },
                                        yearButton: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                }
                                            }
                                        },
                                        monthButton: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                },
                                            }
                                        },
                                        day: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                },
                                            }
                                        },

                                    }}
                                    className="booking-page-input"
                                    views={['year', 'month', 'day']}
                                    label={t("reserve-from-date")}
                                    value={fromDate}
                                    onChange={(newFromDate) => setFromDate(newFromDate)}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disablePast
                                    closeOnSelect

                                    autoFocus
                                    slotProps={{
                                        inputAdornment: {

                                        },
                                        layout: {
                                            sx: {
                                                color: '#ed6c02',
                                                border: '2px solid #ed6c02', backgroundColor: 'rgba(119, 119, 119, 0.19)',

                                            }
                                        },
                                        textField: {
                                            InputLabelProps: {
                                                sx: {
                                                    color: 'white',
                                                },
                                                color: 'warning',
                                            },
                                            inputProps: {
                                                style: {
                                                    color: 'white'
                                                }
                                            },
                                            focused: false

                                        },

                                        rightArrowIcon: {
                                            sx: {
                                                color: '#ed6c02'
                                            }
                                        },
                                        leftArrowIcon: {
                                            sx: {
                                                color: '#ed6c02'
                                            }
                                        },
                                        yearButton: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                }
                                            }
                                        },
                                        monthButton: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                },
                                            }
                                        },
                                        day: {
                                            sx: {
                                                ":focus": {
                                                    backgroundColor: '#ed6c02 !important'

                                                },
                                            }
                                        },

                                    }}
                                    className="booking-page-input"
                                    color='warning'
                                    minDate={fromDate}
                                    views={['year', 'month', 'day']}
                                    label={t("reserve-to-date")}
                                    value={toDate}
                                    onChange={(newToDate) => setToDate(newToDate)}
                                />
                            </LocalizationProvider>
                        </Box>
                                <TextField style={{display: 'none'}} value={fromDate} name='from_date'/>
                                <TextField style={{display: 'none'}} value={toDate} name='to_date'/>
                        <TextField
                            className="booking-page-input"
                            color='warning'
                            label={t('reserve-first-name')}
                            variant="outlined"
                            fullWidth
                            name="user_name"
                            margin="normal"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            InputProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            sx={{ backgroundColor: '', color: 'white' }}


                        />
                        <TextField
                            label={t('reserve-last-name')}
                            variant="outlined"
                            className="booking-page-input"
                            color='warning'
                            fullWidth
                            InputProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            name="user_surname"
                            margin="normal"
                            value={surname}
                            onChange={(e) => setSurName(e.target.value)}
                            required
                            sx={{ backgroundColor: '' }}


                        />
                        <TextField
                            label={t('reserve-phone')}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: 'white'
                                }
                            }}
                            margin="normal"
                            name='from_phone_number'
                            type='number'
                            color='warning'
                            className="booking-page-input"

                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                            sx={{ backgroundColor: '' }}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            disabled={!phoneNumber || !firstName || !surname}
                            variant="contained"
                            color="warning"
                            sx={{ mt: 3, mb: 2 }}

                        >
                            {t('reserve-button-text')}
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </Container>
    );
};

export default Booking;
